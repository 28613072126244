import React, {Component} from 'react'

class Education extends Component {
    constructor(props) {
        super(props)
        this.state = {
            education: this.props.education
        }
    }


    render() {
        return (
            <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="utdannelse">
                <div className="w-100">
                    <h2 className="mb-5">Utdannelse</h2>
                    {this.getEducations()}
                </div>
            </section>
        )
    }

    getEducations() {
        const arr = []
        for (let i = 0; i < this.state.education.length; i++) {
            arr.push(this.formatEducation(i))
        }
        return arr
    }

    formatEducation(index = 0) {
        const {school, title, start, end} = this.state.education[index]

        return (
            <div
                className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5"
                key={index}
            >
                <div className="resume-content">
                    <h3 className="mb-0">{school}</h3>
                    <div>{title}</div>
                </div>
                <div className="resume-date text-md-right">
                    <span className="text-primary">{start} - {end}</span>
                </div>
            </div>
        )
    }
}

export default Education