import React, {Component} from 'react'

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            info: props.info,
            links: props.links,
        }
    }

    render() {
        return (
            <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="om">
                <div className="w-100">
                    <h1 className="mb-0">
                        {this.getName()}
                    </h1>
                    <div className="subheading mb-5">
                        {this.state.info.address}, {this.state.info.postal} {this.state.info.city} · {this.getPhone()} · <a
                        href={`mailto:${this.state.info.email}`}>{this.state.info.email}</a>
                    </div>

                    <p className="lead mb-5">Jeg er en nylig utdannet student med ingen arbeids erfaring, men mye
                        skole-arbeids
                        erfaring i diverse CSS- og JavaScript Rammeverk</p>
                    <div className="social-icons">
                        <a href="/" className="d-none">
                            <i className="fab fa-linkedin-in"/>
                        </a>
                        <a href={`https://github.com/${this.state.links.github}`}>
                            <i className="fab fa-github"/>
                        </a>
                        <a href={`https://twitter.com/${this.state.links.twitter}`}>
                            <i className="fab fa-twitter"/>
                        </a>
                        <a href={`https://facebook.com/${this.state.links.facebook}`}>
                            <i className="fab fa-facebook-f"/>
                        </a>
                    </div>
                </div>
            </section>
        )
    }

    getName() {
        const nameArr = this.state.info.name.split(' ')

        const fname = nameArr.slice(0, -1).join(' ')
        const lname = nameArr.slice(-1).join(' ')

        return (
            <React.Fragment>
                {fname} <span className="text-primary">{lname}</span>
            </React.Fragment>
        )
    }

    getPhone() {
        const phoneStr = this.state.info.phone.toString()

        let phone = ''
        for (let i = 0; i < phoneStr.length; i++) {
            if (i === 3 || i === 5) phone += ' '
            phone += phoneStr[i]
        }

        return phone
    }
}

export default About