import React, {Component} from 'react'
import {BrowserRouter as Router, Route} from "react-router-dom"

import NavBar from "./components/navbar"
import About from "./components/about"
import Education from "./components/education"
import Skills from "./components/skills"
import Projects from "./components/projects"

import './App.css'

class App extends Component {
    state = {
        info: {
            name: "Mathias Nervik",
            address: "Olaf Bulls veg 4",
            postal: 7024,
            city: "Trondheim",
            phone: 41478082,
            email: "nervik.mathias@gmail.com",
        },
        links: {
            github: 'mnervik',
            twitter: 'mnervik94',
            facebook: 'mnervik1',
        },
        education: [
            {
                school: 'Noroff',
                title: 'Front-end Development',
                start: 'August 2019',
                end: 'Juni 2019'
            }
        ],
        skills: {
            html5: true,
            css: true,
            js: true,
            nodejs: true,
            sass: true,
            less: false,
            gulp: true,
            npm: true,
            react: true,
            angular: false,
            grunt: false,
        },
    }

    render() {
        return (
            <Router>
                <NavBar state={this.state}/>

                <Route
                    path="/" exact
                    render={() => (
                        <About
                            info={this.state.info}
                            links={this.state.links}
                        />
                    )}
                />

                <Route
                    path="/utdannelse/"
                    render={() => (
                        <Education
                            education={this.state.education}
                        />
                    )}
                />

                <Route
                    path="/ferdigheter/"
                    render={() => (
                        <Skills
                            skills={this.state.skills}
                        />
                    )}
                />

                <Route
                    path="/prosjekter/"
                    component={Projects}
                />
            </Router>
        )
    }
}

export default App
