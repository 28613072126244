import React, {Component} from 'react'

class Projects extends Component {
    render() {
        return (
            <section className="resume-section p-3 p-lg-5 d-flex justify-content-center" id="experience">
                <div className="w-100">
                    <h2 className="mb-5">Prosjekter</h2>

                    <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div className="resume-content">
                            <h3 className="mb-0">Portfolio</h3>
                            <p>Denne siden er laget og vedlikeholdt av react</p>
                        </div>
                        <div className="resume-date text-md-right">
                            <span className="text-primary">August 2019 - Present</span>
                        </div>
                    </div>

                    <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                        <div className="resume-content">
                            <h3 className="mb-0">Pong Spill</h3>
                            <div className="subheading mb-3">GitHub Prosjekt</div>
                            <p>En etterligning av det klassiske pong spillet, bygget med JavaScript og Canvas</p>
                        </div>
                        <div className="resume-date text-md-right">
                            <span className="text-primary">Juni 2019 - Juli 2019</span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Projects