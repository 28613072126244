import React, {Component} from 'react'
import {Link} from "react-router-dom"
import profileImg from '../profile.jpg'


class NavBar extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
                <Link to="/" className="navbar-brand">
                    <span className="d-block d-lg-none">Mathias Nervik</span>
                    <span className="d-none d-lg-block">
                        <img
                            className="img-fluid img-profile rounded-circle mx-auto mb-2"
                            src={profileImg}
                            alt="profile"
                        />
                    </span>
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar">
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/" className="nav-link js-scroll-trigger">Om meg</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/utdannelse/" className="nav-link js-scroll-trigger">Utdannelse</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/ferdigheter/" className="nav-link js-scroll-trigger">Ferdigheter</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/prosjekter/" className="nav-link js-scroll-trigger">Prosjekter</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default NavBar