import React, {Component} from 'react'

class Skills extends Component {
    state = {
        skills: this.props.skills
    }

    render() {
        return (
            <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="ferdigheter">
                <div className="w-100">
                    <h2 className="mb-5">Ferdigheter</h2>

                    <div className="subheading mb-3">Programmerings språk &amp; Verktøy</div>
                    <ul className="list-inline dev-icons">{this.formatSkills()}</ul>

                    <div className="subheading mb-3">Workflow</div>
                    <ul className="fa-ul mb-0">
                        <li>
                            <i className="fa-li fa fa-check"/>
                            Mobil først, Responsiv Design
                        </li>
                        <li>
                            <i className="fa-li fa fa-check"/>
                            Testing på tvers av nettlesere &amp; Feilsøking
                        </li>
                        <li className="d-none">
                            <i className="fa-li fa fa-check"/>
                            Cross Functional Teams
                        </li>
                        <li className="d-none">
                            <i className="fa-li fa fa-check"/>
                            Agile Development &amp; Scrum
                        </li>
                    </ul>
                </div>
            </section>
        )
    }

    formatSkills() {
        let id = 1
        // eslint-disable-next-line array-callback-return
        return Object.keys(this.state.skills).map((key) => {
            if (this.state.skills[key]) {
                switch (key) {
                    case 'html5':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-html5"/>
                            </li>
                        )
                    case 'css':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-css3-alt"/>
                            </li>
                        )
                    case 'js':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-js-square"/>
                            </li>
                        )
                    case 'angular':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-angular"/>
                            </li>
                        )
                    case 'react':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-react"/>
                            </li>
                        )
                    case 'nodejs':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-node-js"/>
                            </li>
                        )
                    case 'sass':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-sass"/>
                            </li>
                        )
                    case 'less':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-less"/>
                            </li>
                        )
                    case 'gulp':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-gulp"/>
                            </li>
                        )
                    case 'grunt':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-grunt"/>
                            </li>
                        )
                    case 'npm':
                        return (
                            <li key={id++} className="list-inline-item">
                                <i className="fab fa-npm"/>
                            </li>
                        )
                    default:
                        console.log('Missing Statement')
                }
            }
        })
    }
}

export default Skills